<template>
    <div v-loding="loading">
        <el-card shadow="never" style="margin-bottom:20px">
            <el-row type="flex" justify="space-between">
                <el-row type="flex" align="middle">
                    <span class="card-title">广告账户</span>
                    <account-select v-model="ruleForm.account_ids" placeholder="请选择关联的广告账户" size="small"
                        style="width: 280px;" :popper-append-to-body="true" v-show="!loading && ruleForm.account_id"
                        @change="changeAdAccount" popper-class="ad-series-account-select" />
                </el-row>
                <div>
                    <el-button size="small" plain class="login-button" @click="isLoginDialogShow = true;"
                        v-if="!loading && !ruleForm.fb_user_id">授权Facebook用户</el-button>
                    <div v-else class="login-user">
                        <img :src="ruleForm.fb_avatar" alt="avatar" />
                        <span>{{ ruleForm.fb_name }}</span>
                        <el-button size="small" plain class="login-button"
                            @click="isLoginDialogShow = true;">切换Facebook授权</el-button>
                    </div>
                </div>
            </el-row>
        </el-card>

        <ad-tabs ref="adTabsRef" :loading="gridOptions.loading" :selectList="selectList" @change="e => changeTabs(e)"
            @close="e => closeTabs(e)">
            <template #default>
                <el-empty :image-size="300" v-if="!loading && !ruleForm.fb_user_id">
                    <template #description>
                        <span class="ad-series-empty-desc">
                            暂无数据，请先<el-button type="text" @click="isLoginDialogShow = true">授权Facebook用户</el-button>
                        </span>
                    </template>
                    <template #image>
                        <img src="@/assets/empty.gif" />
                    </template>
                </el-empty>
                <vxe-grid ref="xGridRef" v-bind="gridOptions" v-on="gridEvents" :data="dataList[tabIndex]" v-else>
                    <!--自定义插槽 toolbar buttons 插槽-->
                    <template #toolbar_buttons>
                        <el-button size="small" type="primary" @click="$router.push('/ad_template')"
                            icon="el-icon-plus">创建广告</el-button>
                        <el-button size="small" type="primary" @click="$router.push('/batch_ad_template')"
                            icon="el-icon-plus">批量创建广告</el-button>
                        <el-popover placement="bottom" :width="80" trigger="hover"
                            popper-class="ad-series-operate-popover">
                            <template #reference>
                                <div class="operate-button" v-show="selectList[tabIndex].length > 0">
                                    <el-icon>
                                        <i class="iconfont icon-piliangcaozuo"></i>
                                    </el-icon>
                                    <span>操作</span>
                                </div>
                            </template>
                            <div class="operate-button-list">
                                <el-button type="text" size="mini" icon="iconfont icon-piliangkaiqi"
                                    @click="batchSwitch('open')">开启</el-button>
                                <el-button type="text" size="mini" icon="iconfont icon-piliangguanbi"
                                    @click="batchSwitch('close')">关闭</el-button>
                                <el-button type="text" size="mini" icon="iconfont icon-yusuanxiugai"
                                    @click="editBudgetClick">预算</el-button>
                                <el-button type="text" size="mini" icon="iconfont icon-guize" @click="editRuleClick"
                                    v-show="tabIndex === 0">规则</el-button>
                            </div>
                        </el-popover>
                    </template>
                    <!--自定义插槽 toolbar tools 插槽-->
                    <template #toolbar_tools>
                        <vxe-checkbox v-model="ruleForm.is_rule" size="medium" style="margin-right: 12px;"
                            @change="changeIsRule" v-show="tabIndex === 0">仅显示启用规则的广告</vxe-checkbox>
                        <el-input v-model="ruleForm.keyword" placeholder="请输入精准ID或名称搜索" size="small"
                            @keyup.enter.native="handleSearch" @change="handleSearchChange" clearable>
                            <template #append>
                                <el-button icon="iconfont icon-sousuo" @click="handleSearch" />
                            </template>
                        </el-input>
                        <el-date-picker v-model="ruleForm.timeRange" type="daterange" size="small"
                            start-placeholder="开始" end-placeholder="结束" value-format="X"
                            :disabled-date="e => base.getDefaultDisabledDate(e, 1704038400)" range-separator="至"
                            :clearable="false" style="width: 230px;" @change="changeTimeRange"
                            :shortcuts="base.getAdSeriesDatePickerShortcuts()" />
                        <vxe-button icon="iconfont icon-xiazai" circle @click="xGridRef.exportData({
                            columnFilterMethod({ column }) {
                                return column.type !== 'checkbox'
                            }
                        })" style="margin-left: 12px;" v-if="false"></vxe-button>
                    </template>
                    <!-- 名称单元格插槽 -->
                    <template #name_default="{ row }">
                        <div class="cell-name" @click="cellNameClick(row)">
                            <div>
                                <el-icon v-show="tabIndex === 0" style="margin-right: 6px;">
                                    <i class="iconfont icon-guanggaoxilie"></i>
                                </el-icon>
                                <el-icon v-show="tabIndex === 1" style="margin-right: 6px;">
                                    <i class="iconfont icon-guanggaozu"></i>
                                </el-icon>
                                <span>{{ row.name }}</span>
                            </div>
                            <div class="chart-button" v-if="tabIndex === 0"
                                @click.stop="chartData = row; isChartDrawerShow = true">
                                <el-icon>
                                    <i class="iconfont icon-chakanbaobiao"></i>
                                </el-icon>
                                <span>查看报表</span>
                            </div>
                        </div>
                    </template>
                    <!-- 开关单元格插槽 -->
                    <template #switch_default="{ row }">
                        <el-switch v-model="row.switch" :inactive-value="0" :active-value="1"
                            @change="switchChange(row.id, row.switch)" />
                    </template>
                    <!-- 状态单元格插槽 -->
                    <template #status_default="{ row }">
                        <span class="cell-status" :style="statusCellColor(row.status)"></span>
                        {{ row.status }}
                    </template>
                    <!-- 预算单元格插槽 -->
                    <template #budget_default="{ row }">
                        <span v-if="row.daily_type === ''">{{ row.daily_budget }}</span>
                        <div v-else class="cell-budget">
                            <span>{{ row.daily_budget !== 0 ? '$' + base.thousands(row.daily_budget) : row.daily_budget
                                }}</span>
                            <div>
                                <span>{{ row.daily_type }}</span>
                                <el-icon @click="budgetEditData = [row]; isOperateDrawerShow = true">
                                    <i class="iconfont icon-yusuanxiugai"></i>
                                </el-icon>
                            </div>
                        </div>
                    </template>
                    <!-- 自动规则单元格插槽 -->
                    <template #rule_default="{ row }">
                        <div class="cell-rule" v-if="tabIndex === 0">
                            <span style="color: #595959;">{{ !row.rule_id ? '无规则' :
                                `启用1条规则`
                                }}</span>
                            <el-icon @click="ruleEditData = [row]; isRuleDrawerShow = true">
                                <i class="iconfont icon-yusuanxiugai"></i>
                            </el-icon>
                        </div>
                    </template>
                    <!-- 名称表尾单元格插槽 -->
                    <template #name_footer="{ row }">
                        <span>{{ (row.count || 0) + '条' + row.name }}</span>
                    </template>
                    <!-- 预算表尾单元格插槽 -->
                    <template #budget_footer="{ row }">
                        <span style="color: #595959;">--</span>
                    </template>
                    <!-- 自动规则表尾单元格插槽 -->
                    <template #rule_footer="{ row }">
                        <span style="color: #595959;">--</span>
                    </template>
                </vxe-grid>
            </template>
        </ad-tabs>
    </div>

    <!-- FB授权弹窗 -->
    <LoginDialog v-if="isLoginDialogShow" @close="isLoginDialogShow = false;" @submit="handleFBAuth" />

    <!-- 图表弹窗 -->
    <ChartDrawer v-if="isChartDrawerShow" @close="isChartDrawerShow = false" :data="chartData" :type="tabIndex" />

    <!-- 预算编辑弹窗 -->
    <OperateDrawer v-if="isOperateDrawerShow" @close="isOperateDrawerShow = false" @submit="handleEditBudget()"
        :data="budgetEditData" :type="tabIndex" />

    <!-- 自动规则编辑弹窗 -->
    <RuleOperateDrawer v-if="isRuleDrawerShow" @close="isRuleDrawerShow = false" @submit="handleEditBudget()"
        :data="ruleEditData" />
</template>

<script setup>
import { ref, reactive, onMounted, provide, nextTick, getCurrentInstance, defineAsyncComponent } from "vue";
import AdTabs from '@/views/ads/adSeries/AdTabs.vue';
import AccountSelect from '@/components/base/AccountSelect.vue';
import base from '@/base/filters/base'
import api from '@/request/api/ad'
import { ElMessage, ElLoading } from 'element-plus'

const LoginDialog = defineAsyncComponent(() =>
    import('@/views/ads/adTemplate/LoginDialog.vue')
)

const OperateDrawer = defineAsyncComponent(() =>
    import('./OperateDrawer.vue')
)

const RuleOperateDrawer = defineAsyncComponent(() =>
    import('./RuleOperateDrawer.vue')
)

const ChartDrawer = defineAsyncComponent(() =>
    import('./ChartDrawer.vue')
)

const { proxy } = getCurrentInstance()

const xGridRef = ref();
const adTabsRef = ref();
const loading = ref(false) // 检查授权过期请求loading
const ruleForm = ref({
    account_id: '', // FB广告账户id
    account_ids: {}, // el-select绑定FB广告账号id
    fb_user_id: '', // 授权FB用户id
    fb_avatar: '', // 授权FB头像
    fb_name: '', // 授权FB名称
    timeRange: base.getDefaultDuration(), // 时间范围
    keyword: '', // 搜索关键字
    is_rule: false // 是否显示启用规则的广告
})
const tabIndex = ref(0)
const isLoginDialogShow = ref(false)
const isChartDrawerShow = ref(false)
const isOperateDrawerShow = ref(false)
const isRuleDrawerShow = ref(false)
const budgetEditData = ref([])
const ruleEditData = ref([])
const chartData = ref({})
const accountOptions = ref([])
provide('accountOptions', accountOptions)
const pageList = ref([1, 1, 1]) // 广告系列、广告组、广告当前页码
const totalList = ref([0, 0, 0]) // 广告系列、广告组、广告总条数
const dataList = ref([
    [], // 广告系列
    [], // 广告组
    [] // 广告数据
])
const selectList = ref([
    [], // 广告系列
    [], // 广告组
    [] // 广告数据
])

const gridOptions = reactive({
    id: "vxe-grid",
    height: "auto",
    border: "inner",
    showOverflow: true,
    checkboxConfig: { reserve: true, highlight: true },
    rowConfig: { height: 60, keyField: 'id', isHover: true },
    columns: [
        { type: "checkbox", width: 40, align: "center", showOverflow: false },
        { field: "switch", title: "开/关", minWidth: 80, slots: { default: 'switch_default' } },
        { field: "status", title: "名称", minWidth: 320, slots: { default: 'name_default', footer: 'name_footer' } },
        { field: "status", title: "状态", minWidth: 180, slots: { default: 'status_default' } },
        { field: "temp", title: "使用模板", minWidth: 180, align: "right" },
        {
            field: "daily_budget",
            title: "预算",
            headerAlign: "right",
            align: "right",
            minWidth: 180,
            slots: { default: 'budget_default', footer: 'budget_footer' }
        },
        {
            field: "rule_id",
            title: "自动规则",
            headerAlign: "right",
            align: "right",
            minWidth: 180,
            slots: { default: 'rule_default', footer: 'rule_footer' }
        },
        {
            field: "spend",
            title: "消耗",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? `$${base.thousands(cellValue)}` : cellValue
            },
        },
        {
            field: "clicks",
            title: "点击数",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        },
        {
            field: "impressions",
            title: "展示数",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        },
        {
            field: "reach",
            title: "转化数",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        },
        {
            field: "cpm",
            title: "CPM",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        },
        {
            field: "cpc",
            title: "CPC",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        },
        {
            field: "ctr",
            title: "CTR",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        },
        {
            field: "roas",
            title: "ROAS",
            headerAlign: "right",
            align: "right",
            minWidth: 150,
            formatter({ cellValue }) {
                return cellValue !== 0 ? base.thousands(cellValue) : cellValue
            },
        }
    ],
    tooltipConfig: {
        contentMethod: ({ type, column, row, items, _columnIndex }) => {
            const { field } = column
            // 重写默认的提示内容
            if (field === 'name') {
                return row.name
            }
            // 其余的单元格使用默认行为
            return null
        }
    },
    toolbarConfig: {
        slots: {
            buttons: 'toolbar_buttons',
            tools: 'toolbar_tools'
        },
        custom: {
            icon: "iconfont icon-shezhi",
        }
    },
    customConfig: {
        allowFixed: false,
        storage: true,
        checkMethod({ column }) {
            if (['name', 'switch', 'status', 'temp', 'daily_budget', 'rule_id', 'rule', 'spend'].includes(column.field)) {
                return false
            }
            return true
        }
    },
    exportConfig: { filename: '广告数据', type: 'xlsx' },
    showFooter: true,
    footerData: [{ name: "广告系列数据汇总" }]
});

const gridEvents = {
    checkboxChange(params) {
        selectList.value[tabIndex.value] = xGridRef.value.getCheckboxRecords()
    },
    checkboxAll(params) {
        selectList.value[tabIndex.value] = xGridRef.value.getCheckboxRecords()
    },
    scroll(params) {
        // Y轴滚动触底加载数据
        const scrollHeight = params.$event.target.scrollHeight
        const clientHeight = params.$event.target.clientHeight
        const scrollTop = params.$event.target.scrollTop
        if (scrollTop + clientHeight >= scrollHeight - 1 && params.isY) {
            fetchData()
        }
    }
}

const fetchData = (keyword = '') => {
    return new Promise((resolve, reject) => {
        if (dataList.value[tabIndex.value].length > 0 && pageList.value[tabIndex.value] > Math.ceil(totalList.value[tabIndex.value] / 50)) return resolve() // 防止翻到最后一页还重复请求
        gridOptions.loading = true
        if (tabIndex.value === 0) {
            api.series.getSeriesList(ruleForm.value.account_id, ruleForm.value.timeRange, ruleForm.value.fb_user_id, pageList.value[tabIndex.value], keyword, ruleForm.value.is_rule || null).then(res => {
                dataList.value[tabIndex.value] = [...dataList.value[tabIndex.value], ...res.data]
                totalList.value[tabIndex.value] = res.total
                pageList.value[tabIndex.value] += 1
            }).finally(() => {
                gridOptions.loading = false
                resolve()
            })
        }
        if (tabIndex.value === 1) {
            api.series.getGroupList(ruleForm.value.account_id, ruleForm.value.timeRange, ruleForm.value.fb_user_id, selectList.value[0].map(item => item.id).join(','), pageList.value[tabIndex.value], keyword).then(res => {
                dataList.value[tabIndex.value] = [...dataList.value[tabIndex.value], ...res.data]
                totalList.value[tabIndex.value] = res.total
                pageList.value[tabIndex.value] += 1
            }).finally(() => {
                gridOptions.loading = false
                resolve()
            })
        }
        if (tabIndex.value === 2) {
            api.series.getAdList(ruleForm.value.account_id, ruleForm.value.timeRange, ruleForm.value.fb_user_id, selectList.value[0].map(item => item.id).join(','), selectList.value[1].map(item => item.id).join(','), pageList.value[tabIndex.value], keyword).then(res => {
                dataList.value[tabIndex.value] = [...dataList.value[tabIndex.value], ...res.data]
                totalList.value[tabIndex.value] = res.total
                pageList.value[tabIndex.value] += 1
            }).finally(() => {
                gridOptions.loading = false
                resolve()
            })
        }
    })
}

const fetchTotalData = (keyword = '') => {
    if (tabIndex.value === 0) {
        api.series.getTotalList(1, ruleForm.value.account_id, ruleForm.value.timeRange, ruleForm.value.fb_user_id, '', '', keyword, ruleForm.value.is_rule || null).then(res => {
            const data = res.data.data
            Object.keys(data).forEach(key => {
                data[key] = data[key] !== 0 ? `${base.thousands(data[key])}` : data[key]
            });
            data.spend = data.spend !== 0 ? '$' + data.spend : data.spend
            gridOptions.footerData = [{ name: "广告系列数据汇总", count: res.data.campaign_num, ...data }]
        })
    }
    if (tabIndex.value === 1) {
        api.series.getTotalList(2, ruleForm.value.account_id, ruleForm.value.timeRange, ruleForm.value.fb_user_id, selectList.value[0].map(item => item.id).join(','), '', keyword).then(res => {
            const data = res.data.data
            Object.keys(data).forEach(key => {
                data[key] = data[key] !== 0 ? `${base.thousands(data[key])}` : data[key]
            });
            data.spend = data.spend !== 0 ? '$' + data.spend : data.spend
            gridOptions.footerData = [{ name: "广告组数据汇总", count: res.data.adset_num, ...data }]
        })
    }
    if (tabIndex.value === 2) {
        api.series.getTotalList(3, ruleForm.value.account_id, ruleForm.value.timeRange, ruleForm.value.fb_user_id, selectList.value[0].map(item => item.id).join(','), selectList.value[1].map(item => item.id).join(','), keyword).then(res => {
            const data = res.data.data
            Object.keys(data).forEach(key => {
                data[key] = data[key] !== 0 ? `${base.thousands(data[key])}` : data[key]
            });
            data.spend = data.spend !== 0 ? '$' + data.spend : data.spend
            gridOptions.footerData = [{ name: "广告数据汇总", count: res.data.ad_num, ...data }]
        })
    }
}

const statusCellColor = (status) => {
    switch (status) {
        case '投放中':
            return 'background-color: #00f1a2;'
        case '已暂停':
            return 'background-color: #d9d9d9;'
        case '已关闭':
            return 'background-color: #d9d9d9;'
        case '审核中':
            return 'background-color: #FFB900;'
        case '错误':
            return 'background-color: #f56c6c;'
        default:
            return 'background-color: #d9d9d9;'
    }
}

/** 检查用户授权 */
const checkAuth = () => {
    loading.value = true
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('CurrentFBAuthUser')) {
            const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
            api.template.getFBAuthExpire(user.fb_user_id).then((res) => {
                if (res.code === 200) {
                    ruleForm.value.fb_user_id = user.fb_user_id
                    ruleForm.value.fb_avatar = user.fb_avatar
                    ruleForm.value.fb_name = user.fb_name
                }
                resolve()
            }).catch((error) => {
                resolve()
            }).finally(() => {
                loading.value = false
            })
        } else {
            loading.value = false
            resolve()
        }
    })
}

/** 获取FB用户所有广告账户 */
const getAccountList = () => {
    return new Promise((resolve, reject) => {
        api.series
            .getAccountList(ruleForm.value.fb_user_id)
            .then((res) => {
                accountOptions.value = res.data
                if (localStorage.getItem('AdTemplateAccountId')) {
                    ruleForm.value.account_id = localStorage.getItem('AdTemplateAccountId')
                    ruleForm.value.account_ids = res.data.filter(item => item.account_id === ruleForm.value.account_id)[0]
                } else {
                    ruleForm.value.account_id = res.data[0].account_id
                    ruleForm.value.account_ids = res.data[0]
                }
                resolve()
            })
    })
}

/** 更改FB授权状态 */
const handleFBAuth = async (user) => {
    ruleForm.value.account_id = user.account_id
    ruleForm.value.fb_user_id = user.fb_user_id
    ruleForm.value.fb_avatar = user.fb_avatar
    ruleForm.value.fb_name = user.fb_name
    await getAccountList()
    isLoginDialogShow.value = false
    if (tabIndex.value === 1 || tabIndex.value === 2) {
        dataList.value[0] = []
        adTabsRef.value.onTabChange(0)
    } else {
        dataList.value[0] = []
        pageList.value[0] = 1
        totalList.value[0] = 0
        fetchData()
        fetchTotalData()
    }
}

const changeTabs = async (e) => {
    tabIndex.value = e
    if (e === 1 || e === 2) {
        const $table = xGridRef.value
        if ($table) $table.hideColumn('temp')
        if ($table) $table.hideColumn('rule_id')
    } else {
        const $table = xGridRef.value
        if ($table) $table.showColumn('temp')
        if ($table) $table.showColumn('rule_id')
    }
    if (!ruleForm.value.fb_user_id) return
    // 有数据不请求
    if (dataList.value[0].length > 0 && e === 0) {
        fetchTotalData(ruleForm.value.keyword)
        if (selectList.value[e].length === 0) {
            // 一定要等数据加载完再清空选中行，否则会出现清空错乱
            nextTick(() => {
                xGridRef.value.clearCheckboxRow()
            })
        }
        return
    }
    pageList.value[e] = 1
    totalList.value[e] = 0
    dataList.value[e] = []
    fetchTotalData()
    await fetchData()
    if (selectList.value[e].length === 0) {
        // 一定要等数据加载完再清空选中行，否则会出现清空错乱
        xGridRef.value.clearCheckboxRow()
    }
}

const closeTabs = async (e) => {
    selectList.value[e] = []
    if (e === tabIndex.value) {
        xGridRef.value.clearCheckboxRow()
    } else {
        pageList.value[tabIndex.value] = 1
        totalList.value[tabIndex.value] = 0
        dataList.value[tabIndex.value] = []
        fetchTotalData()
        await fetchData()
        if (selectList.value[tabIndex.value].length === 0) {
            // 一定要等数据加载完再清空选中行，否则会出现清空错乱
            xGridRef.value.clearCheckboxRow()
        }
    }
}

/** 切换广告账户 */
const changeAdAccount = () => {
    pageList.value = [1, 1, 1]
    totalList.value = [0, 0, 0]
    dataList.value = [[], [], []]
    ruleForm.value.account_id = ruleForm.value.account_ids.account_id
    localStorage.setItem('AdTemplateAccountId', ruleForm.value.account_id)
    fetchData()
    fetchTotalData()
}

/** 切换时间范围 */
const changeTimeRange = () => {
    pageList.value = [1, 1, 1]
    totalList.value = [0, 0, 0]
    dataList.value = [[], [], []]
    fetchData()
    fetchTotalData()
}

const changeIsRule = () => {
    pageList.value = [1, 1, 1]
    totalList.value = [0, 0, 0]
    dataList.value = [[], [], []]
    fetchData(ruleForm.value.keyword)
    fetchTotalData(ruleForm.value.keyword)
}

const cellNameClick = (row) => {
    if (selectList.value[tabIndex.value].length !== 0) xGridRef.value.clearCheckboxRow()
    xGridRef.value.setCheckboxRow([row], true)
    selectList.value[tabIndex.value] = [row]
    if (tabIndex.value === 2) return
    adTabsRef.value.onTabChange(tabIndex.value + 1)
    return
}

/** 批量编辑预算按钮点击 */
const editBudgetClick = () => {
    if (selectList.value[tabIndex.value].length > 50) return ElMessage.warning('最多编辑50条数据')
    budgetEditData.value = selectList.value[tabIndex.value]
    isOperateDrawerShow.value = true
}

/** 批量编辑规则按钮点击 */
const editRuleClick = () => {
    if (selectList.value[tabIndex.value].length > 50) return ElMessage.warning('最多编辑50条数据')
    ruleEditData.value = selectList.value[tabIndex.value]
    isRuleDrawerShow.value = true
}

/** 开关改变 */
const switchChange = (id, status) => {
    api.series.updateStatus(status, id, ruleForm.value.fb_user_id, tabIndex.value + 1).then(res => {
        if (res.code === 200) {
            ElMessage.success(res.msg)
            pageList.value = [1, 1, 1]
            dataList.value = [[], [], []]
            fetchData()
            fetchTotalData()
        }
    })
}

/** 批量修改开关 */
const batchSwitch = (type) => {
    if (selectList.value[tabIndex.value].length > 50) return ElMessage.warning('最多操作50条数据')
    const ids = selectList.value[tabIndex.value].map(item => item.id).join(',')
    const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '正在提交修改中...'
    })
    api.series.updateStatus(type === 'open' ? 1 : 0, ids, ruleForm.value.fb_user_id, tabIndex.value + 1).then(res => {
        if (res.code === 200) {
            proxy.$alert(`以下是开关修改情况:<br>${res.msg}`, '信息', {
                confirmButtonText: '收到',
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                    pageList.value = [1, 1, 1]
                    dataList.value = [[], [], []]
                    fetchData()
                    fetchTotalData()
                }
            })
        }
    }).finally(() => {
        loading.close()
    })
}

/** 预算/规则编辑弹窗提交回调方法 */
const handleEditBudget = () => {
    isOperateDrawerShow.value = false
    isRuleDrawerShow.value = false
    pageList.value = [1, 1, 1]
    dataList.value = [[], [], []]
    fetchData(ruleForm.value.keyword)
    fetchTotalData(ruleForm.value.keyword)
}

const handleSearch = () => {
    pageList.value[tabIndex.value] = 1
    totalList.value[tabIndex.value] = 0
    dataList.value[tabIndex.value] = []
    selectList.value[tabIndex.value] = []
    fetchData(ruleForm.value.keyword)
    fetchTotalData(ruleForm.value.keyword)
}

const handleSearchChange = () => {
    if (ruleForm.value.keyword === '') {
        console.log(6666666);
        handleSearch()
    }
}

onMounted(async () => {
    await checkAuth()
    if (ruleForm.value.fb_user_id) {
        await getAccountList()
        fetchData()
        fetchTotalData()
    }
})
</script>

<style lang="less">
.ad-series-account-select {
    .el-scrollbar__bar {
        display: none !important;
    }

    .el-select-dropdown__list {
        margin: 0 !important;
    }
}

.ad-series-operate-popover {
    padding: 0 !important;
}
</style>
<style lang="less" scoped>
.card-title {
    font-family: PingFang SC-Medium;
    font-weight: 600;
    font-size: 16px;
    color: #262626;
    margin-right: 8px;
}

:deep(.login-button.el-button) {
    border: 1px solid #2b82ff;
    font-size: 14px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    color: #2b82ff;
}

.login-user {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 16px;

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    span {
        line-height: 16px;
        vertical-align: middle;
    }
}

.operate-button {
    box-sizing: border-box;
    width: 80px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #2B82FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    cursor: pointer;
    color: #2B82FF;
    font-family: PingFang SC-Regular;

    .el-icon {
        margin-right: 6px;
    }
}

.operate-button-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 0;

    :deep(.el-button) {
        width: 100%;
        height: 34px;
        color: #595959;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        margin: 0;

        .iconfont {
            margin-right: 3px;
            vertical-align: -5%;
        }
    }

    :deep(.el-button:hover) {
        color: #2b82ff;
        background: #F5F7FA;
    }
}

:deep(.el-empty) {
    height: 80%;

    .ad-series-empty-desc {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #8c8c8c;

        .el-button {
            color: #2b82ff;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
        }
    }
}

:deep(.vxe-toolbar) {
    padding: 0 0 12px 0;

    .vxe-tools--wrapper {
        margin-right: 12px;

        .el-input {
            width: 230px;
            margin-right: 12px;

            .el-input-group__append {
                box-sizing: border-box;
                width: 32px;
                height: 32px;
                background-color: #ffffff;
                padding: 0;
                position: relative;

                .el-button {
                    position: absolute;
                    right: 50%;
                    bottom: 50%;
                    transform: translate(-60%, 20%);
                    min-height: 32px;
                    padding: 0;
                }
            }
        }
    }

    .vxe-button.type--button.is--circle {
        min-width: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .vxe-checkbox .vxe-checkbox--icon {
        box-shadow: none !important;
    }

    .vxe-checkbox--label {
        color: #595959;
        font-family: PingFangSC-Regular, PingFang SC;
    }
}

:deep(.vxe-table) {
    .vxe-cell {
        padding: 0 20px;
    }

    .vxe-header--row {
        color: #262626;
        background-color: #F5F7FA;
        font-family: 'PingFangSC-Medium, PingFang SC';

        .vxe-checkbox--label {
            color: #262626;
        }

        .vxe-header--column:nth-child(n + 2)::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            width: 1px;
            height: 22px;
            background-color: #e6e6e6;
        }
    }

    .row--hover {
        background-color: #F5F7FA;

        .chart-button {
            display: flex !important;
        }
    }

    .vxe-table-custom--body li:nth-child(-n + 8) {
        pointer-events: none;
        filter: grayscale(100%);
        opacity: 0.5;
    }

    .vxe-table-custom--body li:nth-child(1) {
        display: none;
    }

    .vxe-body--row {
        td:nth-child(2) .vxe-cell {
            padding: 0 0 0 20px;
        }
    }

    .vxe-body--row.row--checked {
        background-color: #F5F7FA;

        .vxe-body--column:nth-child(2) {
            color: #2b82ff;
        }
    }

    .vxe-body--column {
        position: relative;
    }

    .cell-name {
        height: 60px;
        cursor: pointer;

        &>div:first-child {
            line-height: 60px;
        }

        &:hover {
            color: #2b82ff;
        }

        .chart-button {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 9;
            background-color: #F5F7FA;
            width: 112px;
            height: 60px;
            align-items: center;
            justify-content: center;
            color: #2b82ff;

            .el-icon {
                margin-right: 6px;
            }
        }
    }

    .cell-status {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 6px;
        background-color: #d9d9d9;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .cell-budget {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        &>div {
            color: #8c8c8c;

            .el-icon {
                margin-left: 3px;
                cursor: pointer;

                &:hover {
                    color: #2b82ff;
                }
            }
        }

    }

    .cell-rule {
        color: #8c8c8c;

        .el-icon {
            margin-left: 3px;
            cursor: pointer;

            &:hover {
                color: #2b82ff;
            }
        }
    }

    .vxe-footer--row {
        font-weight: 600;
        color: #262626;
        font-family: 'PingFangSC-Medium, PingFang SC';
    }
}
</style>