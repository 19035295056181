<template>
    <el-select v-bind="$attrs" :popper-append-to-body="false" value-key="account_id">
        <el-input v-model="keyword" placeholder="搜索业务资产" @input="filterItem" class="filter-input" size="medium">
            <template #prefix>
                <img src="@/assets/images/report/search.svg" alt="">
            </template>
        </el-input>
        <div class="account-list" v-show="listVisible">
            <el-option v-for="item in accountOptions" :key="item.account_id" :value="item" :label="item.name">
                <span class="name">{{ item.name }}</span>
                <span class="id">广告账户ID：{{ item.account_id }}</span>
            </el-option>
        </div>
        <div v-show="!listVisible" class="empty">未找到指定账户</div>
    </el-select>
</template>

<script setup>
import { ref, inject, watch } from 'vue'

const keyword = ref('')
const initOptions = inject('accountOptions')
const accountOptions = ref([])
const listVisible = ref(true)

const filterItem = () => {
    if (!keyword.value) {
        listVisible.value = true
        accountOptions.value = JSON.parse(JSON.stringify(initOptions.value))
    }
    const arr = initOptions.value.filter(item => item.account_id.includes(keyword.value) || item.name.includes(keyword.value))
    if (arr.length === 0) return listVisible.value = false
    listVisible.value = true
    accountOptions.value = arr
}

watch(initOptions, () => {
    accountOptions.value = JSON.parse(JSON.stringify(initOptions.value))
}, { deep: true, immediate: true })
</script>

<style lang="less" scoped>
:deep(.el-input__inner) {
    color: #262626;
}

.filter-input {
    box-sizing: border-box;
    border: 1px solid #409eff;
    border-radius: 0%;

    :deep(.el-input__inner) {
        border: none;
        padding-left: 40px;
    }

    :deep(.el-input__prefix) {
        display: flex;
        align-items: center;
        left: 12px;
    }
}

.account-list {
    max-height: 224px;
    padding: 4px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 28px;
        background: #d9d9d9;
    }

    &::-webkit-scrollbar-track {
        background: #ededed;
    }

    :deep(.el-select-dropdown__item) {
        height: 48px;
        padding: 2px 44px 2px 12px;
        display: flex;
        flex-direction: column;
        margin-bottom: 4px;

        &::after {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 22px;
            height: 22px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            -webkit-font-smoothing: antialiased;
        }

        &.selected {
            background: rgba(43, 130, 255, 0.1) !important;
            border-radius: 4px !important;
        }

        &.selected::after {
            content: '';
            background: #2B82FF;
            box-shadow: inset 0 0 0 4px #ffffff;
            border: 1px solid #d9d9d9;
        }

        span {
            font-family: 'PingFang SC';
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        .name {
            color: #262626;
        }

        .id {
            color: #595959;
        }
    }
}

.empty {
    padding: 24px 0;
    text-align: center;
}

:deep(.el-select-dropdown__list) {
    min-width: 361px;
    margin: 0 !important;
}
</style>