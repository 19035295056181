import axios from '@/request/http'
import base from '@/request/base'

const template = {
  /** 获取广告模板分类 */
  getTemplateCategory() {
    return axios.get(`${base.admin_url}/cloud/Advoperate/adv_template_category`)
  },

  /**
   * 获取广告模板列表
   * @param {String} data
   */
  getTemplateList(data) {
    return axios.get(`${base.admin_url}/cloud/Advoperate/adv_template_list`, { params: data })
  },

  /**
   * 获取广告模板详情
   * @param {String} id 模板id
   */
  getTemplateDetail(id) {
    return axios.get(`${base.admin_url}/cloud/advoperate/adv_template_detail?id=${id}`)
  },

  /**
   * FB授权登录
   * @param {Object} data 用户FB授权信息
   */
  FBLogin(data) {
    return axios.post(`${base.admin_url}/cloud/Advoperate/fb_authorize`, data)
  },

  /** 获取所有授权用户 */
  getFBAuthUser() {
    return axios.get(`${base.admin_url}/cloud/advoperate/authorize_list`)
  },

  /**
   * 判断授权是否过期
   * @param {String} id 用户id
   */
  getFBAuthExpire(id) {
    return axios.get(`${base.admin_url}/cloud/advoperate/judge_authorize?id=${id}`)
  },

  /**
   * 获取用户所有广告账户
   * @param {String} id 用户id
   * @param {String} page 页码
   * @param {String} keyword 搜索关键字
   */
  getFBAdAccount(id, page, keyword) {
    return axios.get(`${base.admin_url}/cloud/advoperate/account_list?id=${id}&page=${page}&keyword=${keyword}`)
  },

  /**
   * 获取FB账户所有公共主页和像素
   * @param {String} id 账户id
   * @param {String} user_id 用户id
   */
  getPageAndPixel(account_id, user_id) {
    return axios.get(`${base.admin_url}/cloud/advoperate/get_pixel_page?account_id=${account_id}&user_id=${user_id}`)
  },

  /**
   * 获取广告模板受众信息选项
   * @param {Number} t_id
   * @returns
   */
  getAllAudience(t_id) {
    return axios.get(`${base.admin_url}/cloud/advoperate/adv_data?t_id=${t_id}`)
  },

  /**
   * 创建广告
   * @param {Object} data 广告模板信息
   */
  createFBAd(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/create_adv`, data, { isDebounce: true })
  },

  /**
   * 批量创建广告
   * @param {Object} data 广告模板信息
   */
  batchCreateFBAd(data) {
    return axios.post(`${base.admin_url}/cloud/Advoperate/batch_create_adv`, data, { isDebounce: true })
  },

  /** 获取批量创建广告日志 */
  getBatchLog(data) {
    return axios.get(`${base.admin_url}/cloud/advoperate/batch_log`, { params: data })
  },

  /** 获取批量创建广告日志详情 */
  getBatchLogDetail(data) {
    return axios.get(`${base.admin_url}/cloud/advoperate/batch_log_detail`, { params: data })
  },

  /** 判断用户ip是否可访问Facebook */
  getIp() {
    return axios.get(`${base.admin_url}/cloud/advoperate/get_ip`)
  },

  /** 事件埋点 */
  handleEventTracking(data) {
    return axios.post(`${base.admin_url}/cloud/Advoperate/adv_statistics`, data)
  },

  /**
   * 删除我的模板
   * @param {Number} t_id 我的模板id
   */
  deletePresetTemplate(t_id) {
    return axios.post(`${base.admin_url}/cloud/advoperate/delete_preset_template`, { t_id })
  },

  /**
   * 判断我的模板是否重名
   * @param {String} title 模板名称
   */
  judgeTemplateName(title) {
    return axios.get(`${base.admin_url}/cloud/advoperate/judge_same_name?title=${title}`)
  },

  /**
   * 重命名我的模板
   * @param {Object} data
   */
  renameTemplate(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/rename_preset_template`, data)
  },

  /**
   * 创建我的模板
   * @param {Object} data
   */
  createPresetTemplate(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/preset_template`, data)
  },

  /**
   * 获取模板分享链接
   * @param {Number} id
   */
  getShareUrl(id) {
    return axios.post(`${base.admin_url}/cloud/advoperate/share_preset_template?t_id=${id}`)
  },

  /**
   * 处理我的模板分享链接参数
   * @param {String} token
   */
  handleShareUrl(token) {
    return axios.post(`${base.admin_url}/cloud/advoperate/reception_share_preset_template`, { token })
  },

  /**
   * 保存分享的我的模板
   * @param {Object} data
   */
  saveSharedPresetTemplate(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/preservation__share_preset_template`, data)
  },
}

const series = {
  /**
   * 获取广告账户列表
   * @param {String} user_id FB用户id
   */
  getAccountList(user_id) {
    return axios.get(`${base.admin_url}/cloud/advoperate/account_data?user_id=${user_id}`)
  },
  /**
   * 获取广告系列列表
   * @param {String} account_id 广告账户id
   * @param {String} spent_time 广告账户消耗时间
   * @param {String} user_id FB用户id
   * @param {Number} page 分页
   * @param {String} keyword 搜索关键字
   * @param {Number} is_rule 是否显示启用规则广告
   */
  getSeriesList(account_id, spent_time, user_id, page, keyword, is_rule) {
    return axios.get(`${base.admin_url}/cloud/advoperate/campaign_list`, {
      params: { account_id, spent_time, user_id, page, keyword, is_rule },
    })
  },
  /**
   * 获取广告组列表
   * @param {String} account_id 广告账户id
   * @param {String} spent_time 广告账户消耗时间
   * @param {String} user_id FB用户id
   * @param {String} campaign_id 广告系列id
   * @param {Number} page 分页
   * @param {String} keyword 搜索关键字
   */
  getGroupList(account_id, spent_time, user_id, campaign_id, page, keyword) {
    return axios.get(`${base.admin_url}/cloud/advoperate/adset_list`, {
      params: { account_id, spent_time, user_id, campaign_id, page, keyword },
    })
  },
  /**
   * 获取广告列表
   * @param {String} account_id 广告账户id
   * @param {String} spent_time 广告账户消耗时间
   * @param {String} user_id FB用户id
   * @param {String} campaign_id 广告系列id
   * @param {String} adset_id 广告组id
   * @param {Number} page 页码
   * @param {String} keyword 搜索关键字
   */
  getAdList(account_id, spent_time, user_id, campaign_id, adset_id, page, keyword) {
    return axios.get(`${base.admin_url}/cloud/advoperate/ad_list`, {
      params: { account_id, spent_time, user_id, campaign_id, adset_id, page, keyword },
    })
  },
  /**
   * 获取汇总数量和汇总数据
   * @param {Number} type 广告系列1 广告组2 广告3
   * @param {String} account_id 广告账户id
   * @param {String} spent_time 广告账户消耗时间
   * @param {String} user_id FB用户id
   * @param {String} campaign_ids 勾选的广告系列id
   * @param {String} adset_ids 勾选的广告组id
   * @param {String} keyword 搜索关键字
   * @param {Number} is_rule 是否显示启用规则广告
   */
  getTotalList(type, account_id, spent_time, user_id, campaign_ids, adset_ids, keyword, is_rule) {
    return axios.get(`${base.admin_url}/cloud/advoperate/count`, {
      params: { type, account_id, spent_time, user_id, campaign_ids, adset_ids, keyword, is_rule },
    })
  },
  /**
   * 修改状态
   * @param {Number} status
   * @param {String} id
   * @param {String} user_id
   * @param {Number} type
   */
  updateStatus(status, id, user_id, type) {
    return axios.post(`${base.admin_url}/cloud/advoperate/update_adv_status`, { status, id, user_id, type })
  },
  /**
   * 修改预算
   * @param {Array} data json数组
   * @param {String} user_id fb授权用户id
   * @param {Number} type 1广告系列 2广告组
   */
  updateBudget(data, user_id, type) {
    return axios.post(`${base.admin_url}/cloud/advoperate/update_adv_budget`, { data, user_id, type })
  },
  /** 成效分析 */
  getInsights(params) {
    return axios.get(`${base.admin_url}/cloud/advoperate/insights`, { params })
  },
  /**
   * 修改规则
   * @param {Array} rule_ids 规则和系列id json数组
   * @param {String} account_id 账户id
   * @param {String} user_id 用户id
   */
  updateRule(rules, account_id, user_id) {
    return axios.post(`${base.admin_url}/cloud/advoperate/edit_rule`, { rules, account_id, user_id })
  },
}

const rule = {
  /** 获取自动化规则列表 */
  getRuleList(data) {
    return axios.get(`${base.admin_url}/cloud/advoperate/rule_data`, { params: data })
  },
  /** 修改规则状态 */
  updateRuleStatus(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/edit_rule_status`, data)
  },
  /** 获取广告系列信息 */
  getCampaignInfo(data) {
    return axios.get(`${base.admin_url}/cloud/advoperate/rule_campaign`, { params: data })
  },
  /** 选择广告系列 */
  selectCampaign(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/choose_rule_campaign`, data)
  },
  /** 复制规则 */
  copyRule(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/copy_rule`, data)
  },
  /** 删除规则 */
  deleteRule(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/delete_rule`, data)
  },
  /** 获取规则配置 */
  getRuleConfig() {
    return axios.get(`${base.admin_url}/cloud/advoperate/rule_config`)
  },
  /** 新增自定义规则 */
  addCustomRule(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/add_customize_rule`, data)
  },
  /** 编辑自定义规则 */
  editCustomRule(data) {
    return axios.post(`${base.admin_url}/cloud/advoperate/edit_customize_rule`, data)
  },
  /** 获取规则日志 */
  getRuleRecord(data) {
    return axios.get(`${base.admin_url}/cloud/advoperate/rule_log`, { params: data })
  },
}

export default { template, series, rule }
